/* You can add global styles to this file, and also import other style files */
:root {
  --headerFonts: "Playfair Display", serif;
  --bodyFonts: "Rubik", sans-serif;
  --mainColor: #000000;
  --hoverColor: #707272;
  --titleColor: #707272;
  --bodyColor: #555555;
  --whiteColor: #ffffff;
  --transition: 0.5s;
  --fontSize: 16px;
}

body {
  margin: 0;
  padding: 0;
  color: var(--titleColor);
  font-size: var(--fontSize);
  font-family: var(--bodyFonts);
}

/*--------RECAPTCHA--------*/
.grecaptcha-badge {
  visibility: hidden;
}

.is-inpt-invalid {
  background-color: #ffede7 !important;
}
.is-inpt-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 77, 21, 0.25);
}

.captcha {
  padding-top: 0.5rem !important;
  color: #4b5669 !important;
  font-size: 0.8rem !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--titleColor);
  font-weight: 700;
  font-family: var(--headerFonts);
}

a {
  transition: var(--transition);
  color: var(--titleColor);
  text-decoration: none;
  outline: 0 !important;
}
a:hover {
  color: var(--mainColor);
  text-decoration: none;
}

:focus {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  font-size: var(--fontSize);
  color: var(--bodyColor);
  margin-bottom: 15px;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.ptb-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.pt-75 {
  padding-top: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.section-bg {
  background-color: #EEEEEE;
}

.section-title {
  position: relative;
  margin-bottom: 40px;
}
.section-title .sub-title {
  display: block;
  margin-bottom: 8px;
  color: var(--mainColor);
}
.section-title h2 {
  font-size: 45px;
  margin-bottom: 0;
}
.section-title p {
  max-width: 650px;
  margin-top: 15px;
}
.section-title.text-center p {
  margin-left: auto;
  margin-right: auto;
}
.section-title .section-vector {
  text-align: center;
  position: absolute;
  z-index: -1;
  top: -70px;
  right: 0;
  left: 0;
}

.default-btn {
  position: relative;
  padding: 13px 30px;
  display: inline-block;
  color: var(--whiteColor);
  transition: var(--transition);
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
  font-size: var(--fontSize);
  font-weight: 400;
}
.default-btn.with-icon {
  padding: 12px 55px 12px 30px;
}
.default-btn.with-icon i {
  transform: translateY(-50%);
  position: absolute;
  margin-top: 1px;
  line-height: 1;
  right: 30px;
  top: 50%;
}
.default-btn:hover {
  background-color: var(--titleColor);
  border-color: var(--titleColor);
  color: var(--whiteColor);
}

.about-slider .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}
.about-slider .owl-theme .owl-dots {
  left: -4px;
  bottom: -35px;
  position: absolute;
}
.about-slider .owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #dccfcf;
  transition: var(--transition);
}
.about-slider .owl-theme .owl-dots .owl-dot:hover span, .about-slider .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.services-slider .owl-theme .owl-nav {
  margin-top: 0;
}
.services-slider .owl-theme .owl-nav [class*=owl-] {
  top: 50%;
  margin: 0;
  padding: 0;
  left: -40px;
  width: 40px;
  height: 40px;
  border-radius: 0;
  position: absolute;
  color: var(--titleColor);
  transform: translateY(-50%);
  transition: var(--transition);
  background-color: var(--whiteColor);
}
.services-slider .owl-theme .owl-nav [class*=owl-] i {
  left: 0;
  right: 0;
  top: 50%;
  line-height: 0;
  position: absolute;
  transform: translateY(-50%);
}
.services-slider .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -40px;
}
.services-slider .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.testimonial-slider-three .owl-theme .owl-nav {
  margin-top: 0;
}
.testimonial-slider-three .owl-theme .owl-nav [class*=owl-] {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  color: var(--titleColor);
  transition: var(--transition);
  background-color: var(--whiteColor);
  margin-left: 4px;
  margin-right: 4px;
}
.testimonial-slider-three .owl-theme .owl-nav [class*=owl-] i {
  left: 0;
  right: 0;
  top: 50%;
  line-height: 0;
  position: absolute;
  transform: translateY(-50%);
}
.testimonial-slider-three .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.banner-slider .owl-theme .owl-nav {
  margin-top: 0;
}
.banner-slider .owl-theme .owl-nav [class*=owl-] {
  left: 0;
  top: 50%;
  margin: 0;
  padding: 0;
  width: 35px;
  font-size: 18px;
  border-radius: 0;
  position: absolute;
  color: var(--titleColor);
  transform: translateY(-50%);
  transition: var(--transition);
  background-color: none;
}
.banner-slider .owl-theme .owl-nav [class*=owl-] i {
  left: 0;
  right: 0;
  top: 50%;
  line-height: 0;
  position: absolute;
  transform: translateY(-50%);
}
.banner-slider .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 0;
}
.banner-slider .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: none;
  color: var(--whiteColor);
}

@keyframes rotate360 {
  50% {
    -ms-transform: rotate(360deg) scale(0.8);
    -moz-transform: rotate(360deg) scale(0.8);
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
}
@keyframes sping-round {
  0% {
    transform-origin: 300% 50%;
    transform: rotate(0deg);
  }
  50% {
    transform-origin: 300% 50%;
    transform: rotate(360deg);
  }
  50.1% {
    transform-origin: -300% 50%;
    transform: rotate(360deg);
  }
  100% {
    transform-origin: -300% 50%;
    transform: rotate(0deg);
  }
}
@keyframes rotate-in {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 0;
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.scrolltop-button {
  z-index: 100 !important;
}
.scrolltop-button.gray {
  background-color: var(--mainColor) !important;
}

.inner-banner {
  background-color: #f1f1f1;
  padding-top: 50px;
  padding-bottom: 25px;
}
.inner-banner .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.inner-banner .inner-title {
  margin-bottom: 25px;
}
.inner-banner .inner-title h2 {
  font-size: 50px;
  margin-bottom: 15px;
  color: #9fa09d;
}
.inner-banner .inner-title ul {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}
.inner-banner .inner-title ul li {
  color: var(--mainColor);
  display: inline-block;
  position: relative;
  margin-right: 25px;
}
.inner-banner .inner-title ul li::before {
  top: 4px;
  width: 1px;
  content: "";
  right: -13px;
  height: 17px;
  position: absolute;
  transform: rotate(20deg);
  background-color: var(--mainColor);
}
.inner-banner .inner-title ul li:last-child {
  margin-right: 0;
}
.inner-banner .inner-title ul li:last-child::before {
  display: none;
}
.inner-banner .inner-img {
  margin-bottom: 25px;
}

.faq-accordion .panel-group .panel {
  margin-bottom: 25px;
}
.faq-accordion .panel-group .panel .panel {
  border: none;
  padding: 20px;
  display: block;
  border-radius: 0;
  flex-direction: unset;
  background-color: transparent;
  background-color: var(--whiteColor);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.faq-accordion .panel-group .panel .panel .panel-heading {
  padding: 0;
  border-radius: 0;
  border-bottom: none;
  background-color: transparent;
}
.faq-accordion .panel-group .panel .panel .panel-heading .btn-link {
  width: 100%;
  border: none;
  display: block;
  text-align: start;
  position: relative;
  outline: 0 !important;
  text-decoration: none;
  padding: 8px 0 8px 60px;
  color: var(--heading-color);
  box-shadow: unset !important;
  font-size: 18px;
  font-weight: 500;
}
.faq-accordion .panel-group .panel .panel .panel-heading .btn-link::after {
  background-color: #f5f5f5;
  color: var(--heading-color);
  transform: translateY(-50%);
  position: absolute;
  text-align: center;
  border-radius: 50%;
  content: "\ea13";
  line-height: 45px;
  height: 45px;
  width: 45px;
  top: 50%;
  left: 0;
  font-family: "remixicon" !important;
  font-size: 18px;
}
.faq-accordion .panel-group .panel .panel .card-body {
  padding-top: 18px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}
.faq-accordion .panel-group .panel.panel-open .panel .panel-heading .btn-link::after {
  content: "\f1af";
}
.faq-accordion .panel-group .panel:last-child {
  margin-bottom: 0;
}

.pagination-area {
  margin-top: 15px;
  text-align: center;
}
.pagination-area .page-numbers {
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 49px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  color: var(--bodyColor);
  background-color: transparent;
  border: 1px solid rgb(238, 238, 238);
  margin-left: 5px;
  margin-right: 5px;
}
.pagination-area .page-numbers:hover, .pagination-area .page-numbers.current {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.pagination-area .page-numbers i {
  line-height: normal;
  position: relative;
  top: 2px;
}
.pagination-area .ngx-pagination {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.pagination-area .ngx-pagination li {
  color: var(--titleColor);
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-size: var(--fontSize);
}
.pagination-area .ngx-pagination li:first-child {
  margin-left: 0;
}
.pagination-area .ngx-pagination li:last-child {
  margin-right: 0;
}
.pagination-area .ngx-pagination li.disabled {
  padding: 9px 18px;
  color: var(--titleColor);
  background: var(--whiteColor);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pagination-area .ngx-pagination li a {
  padding: 9px 18px;
  color: var(--titleColor);
  transition: var(--transition);
  background: var(--whiteColor);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pagination-area .ngx-pagination li a:hover {
  color: var(--whiteColor);
  background: var(--mainColor);
}
.pagination-area .ngx-pagination li.current {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: var(--mainColor);
  color: var(--whiteColor);
  padding: 9px 18px;
}
.pagination-area .ngx-pagination li.pagination-previous::before {
  position: relative;
  top: -1px;
  margin: 0;
}
.pagination-area .ngx-pagination li.pagination-previous a::before {
  position: relative;
  top: -1px;
  margin: 0;
}
.pagination-area .ngx-pagination li.pagination-next::after {
  position: relative;
  top: -1px;
  margin: 0;
}
.pagination-area .ngx-pagination li.pagination-next a::after {
  position: relative;
  top: -1px;
  margin: 0;
}

.product-quickview-modal {
  max-width: 1320px;
  position: relative;
}
.product-quickview-modal .product-details-image {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  background-color: var(--whiteColor);
  margin-bottom: 25px;
  text-align: center;
}
.product-quickview-modal .product-details-image img {
  padding: 60px;
}
.product-quickview-modal .product-details-desc {
  margin-bottom: 25px;
  padding-left: 15px;
}
.product-quickview-modal .product-details-desc h3 {
  margin-bottom: 17px;
  font-size: 24px;
}
.product-quickview-modal .product-details-desc .rating {
  display: inline-block;
  margin-bottom: 10px;
}
.product-quickview-modal .product-details-desc .rating i {
  line-height: 1;
  font-size: 18px;
  color: #fdc22d;
  margin-right: 4px;
}
.product-quickview-modal .product-details-desc .price {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}
.product-quickview-modal .product-details-desc .price .new-price {
  color: var(--mainColor);
}
.product-quickview-modal .product-details-desc .price .old-price {
  text-decoration: line-through;
  color: var(--bodyColor);
  margin-right: 10px;
}
.product-quickview-modal .product-details-desc .product-category-list {
  list-style-type: none;
  margin-bottom: 20px;
  padding-left: 0;
}
.product-quickview-modal .product-details-desc .product-category-list li {
  margin-bottom: 8px;
  max-width: 230px;
}
.product-quickview-modal .product-details-desc .product-category-list li span {
  float: right;
  color: var(--bodyColor);
}
.product-quickview-modal .product-details-desc .product-category-list li:last-child {
  margin-bottom: 0;
}
.product-quickview-modal .product-details-desc .product-add-btn {
  margin-bottom: 25px;
  position: relative;
}
.product-quickview-modal .product-details-desc .product-add-btn .default-btn {
  margin-right: 15px;
}
.product-quickview-modal .product-details-desc .product-add-btn .products-action {
  display: inline-block;
  list-style-type: none;
  position: relative;
  margin-bottom: 0;
  padding-left: 0;
  top: 5px;
}
.product-quickview-modal .product-details-desc .product-add-btn .products-action li {
  display: inline-block;
  margin-right: 15px;
}
.product-quickview-modal .product-details-desc .product-add-btn .products-action li a {
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  color: var(--mainColor);
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}
.product-quickview-modal .product-details-desc .product-add-btn .products-action li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.product-quickview-modal .product-details-desc .product-share ul {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}
.product-quickview-modal .product-details-desc .product-share ul li {
  display: inline-block;
  margin-right: 6px;
}
.product-quickview-modal .product-details-desc .product-share ul li span {
  color: var(--titleColor);
  display: inline-block;
  padding-right: 5px;
  font-size: 16px;
  font-weight: 600;
}
.product-quickview-modal .product-details-desc .product-share ul li a {
  width: 45px;
  height: 45px;
  display: block;
  font-size: 16px;
  line-height: 42px;
  border-radius: 50%;
  text-align: center;
  color: var(--mainColor);
  background-color: var(--whiteColor);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}
.product-quickview-modal .product-details-desc .product-share ul li a i {
  position: relative;
  top: 2px;
}
.product-quickview-modal .product-details-desc .product-share ul li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.product-quickview-modal .product-details-desc .product-share ul li:last-child {
  margin-right: 0;
}
.product-quickview-modal input[type=number]::-webkit-inner-spin-button, .product-quickview-modal input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
.product-quickview-modal .close {
  top: 0;
  right: 0;
  margin: 0;
  outline: 0;
  z-index: 22;
  opacity: 1;
  padding: 0;
  width: 35px;
  height: 35px;
  border: none;
  font-size: 16px;
  line-height: 39px;
  text-shadow: unset;
  position: absolute;
  color: var(--titleColor);
  transition: var(--transition);
}
.product-quickview-modal .close:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.product-details-tabs .nav-tabs {
  display: block;
  flex-wrap: unset;
  text-align: center;
  border-bottom: none;
  margin-bottom: 30px;
}
.product-details-tabs .nav-tabs .nav-item {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}
.product-details-tabs .nav-tabs .nav-item .nav-link {
  padding: 0;
  border: none;
  color: var(--bodyColor);
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--headerFonts);
}
.product-details-tabs .nav-tabs .nav-item .nav-link:hover, .product-details-tabs .nav-tabs .nav-item .nav-link.active {
  color: var(--mainColor);
}
.product-details-tabs .tab-content {
  padding: 50px;
  border-radius: 10px;
  background-color: var(--whiteColor);
}
.product-details-tabs .tab-content .additional-information {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.product-details-tabs .tab-content .additional-information li {
  border: 1px solid rgba(245, 245, 245, 0.9607843137);
  color: var(--bodyColor);
  border-bottom: none;
  padding: 13px 20px;
}
.product-details-tabs .tab-content .additional-information li:last-child {
  border-bottom: 1px solid rgba(245, 245, 245, 0.9607843137);
}
.product-details-tabs .tab-content .additional-information li span {
  color: var(--titleColor);
  display: inline-block;
  font-weight: 600;
  width: 40%;
}
.product-details-tabs .tab-content .product-details-comments h3 {
  font-size: 24px;
  margin-bottom: 12px;
}
.product-details-tabs .tab-content .product-details-comments .review-title {
  border-bottom: 1px dashed #eeeeee;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.product-details-tabs .tab-content .product-details-comments .review-title .rating {
  display: flex;
  align-items: center;
}
.product-details-tabs .tab-content .product-details-comments .review-title .rating i {
  margin-right: 5px;
  color: #fdc22d;
  font-size: 18px;
  line-height: 1;
}
.product-details-tabs .tab-content .product-details-comments .review-title .rating p {
  position: relative;
  top: 2px;
}
.product-details-tabs .tab-content .product-details-comments .review-title .default-btn {
  margin-top: 15px;
}
.product-details-tabs .tab-content .product-details-comments .review-comments .review-item {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px dashed #eeeeee;
}
.product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content {
  display: flex;
  position: relative;
  margin-bottom: 15px;
  align-items: center;
}
.product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content img {
  max-width: 60px;
  border-radius: 50%;
}
.product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content .content-dtls {
  margin-left: 15px;
}
.product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content .content-dtls h4 {
  margin-bottom: 5px;
  font-size: 18px;
}
.product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content .content-dtls span {
  color: var(--mainColor);
  font-size: 14px;
}
.product-details-tabs .tab-content .product-details-comments .review-comments .review-item h3 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 12px;
}
.product-details-tabs .tab-content .product-details-comments .review-comments .review-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.product-details-tabs .tab-content .product-details-comments .review-form {
  margin-top: 25px;
}
.product-details-tabs .tab-content .product-details-comments .review-form h3 {
  font-size: 24px;
  margin-bottom: 25px;
}
.product-details-tabs .tab-content .product-details-comments .review-form form .form-group {
  margin-bottom: 25px;
}
.product-details-tabs .tab-content .product-details-comments .review-form form .form-group .form-control {
  width: 100%;
  height: 55px;
  border-radius: 0;
  box-shadow: unset;
  padding: 10px 20px;
  color: var(--titleColor);
  font-size: var(--fontSize);
  background-color: #faefed;
  border: 1px solid #faefed;
}
.product-details-tabs .tab-content .product-details-comments .review-form form .form-group .form-control::placeholder {
  color: var(--bodyColor);
  transition: var(--transition);
}
.product-details-tabs .tab-content .product-details-comments .review-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.product-details-tabs .tab-content .product-details-comments .review-form form .form-group textarea.form-control {
  padding-top: 20px;
  height: auto;
}

.notifier__notification--material {
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
}
.notifier__notification--material p {
  color: var(--whiteColor);
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .ptb-75 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .pt-75 {
    padding-top: 35px;
  }
  .pb-75 {
    padding-bottom: 35px;
  }
  .section-title {
    margin-bottom: 30px;
  }
  .section-title .sub-title {
    margin-bottom: 8px;
  }
  .section-title h2 {
    font-size: 26px;
  }
  .section-title p {
    max-width: 100%;
    margin-top: 12px;
  }
  .section-title .section-vector {
    opacity: 0.6;
  }
  .default-btn {
    padding: 11px 25px;
    font-size: 14px;
  }
  .default-btn.with-icon {
    padding: 11px 45px 11px 25px;
  }
  .default-btn.with-icon i {
    right: 25px;
  }
  .about-slider .owl-theme .owl-dots {
    left: auto;
    right: 10px;
    bottom: 10px;
  }
  .services-slider .owl-theme .owl-nav {
    margin-bottom: 25px;
  }
  .services-slider .owl-theme .owl-nav [class*=owl-] {
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    position: relative;
    transform: translateY(0);
    margin-left: 4px;
    margin-right: 4px;
  }
  .services-slider .owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }
  .banner-slider .owl-theme .owl-nav {
    position: absolute;
    text-align: center;
    bottom: 70px;
    right: 0;
    left: 0;
  }
  .banner-slider .owl-theme .owl-nav [class*=owl-] {
    top: 0;
    width: 35px;
    font-size: 18px;
    position: relative;
    transform: translateY(0);
    margin-left: 3px;
    margin-right: 3px;
  }
  .banner-slider .owl-theme .owl-nav [class*=owl-].owl-next {
    left: 0;
    right: 0;
  }
  .inner-banner {
    padding-top: 50px;
    padding-bottom: 25px;
  }
  .inner-banner .container-fluid {
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
  }
  .inner-banner .inner-title {
    text-align: center;
  }
  .inner-banner .inner-title h2 {
    font-size: 26px;
    margin-bottom: 12px;
  }
  .inner-banner .inner-title ul li {
    margin-left: 10px;
    margin-right: 10px;
  }
  .inner-banner .inner-title ul li::before {
    top: 4px;
    right: -10px;
    height: 15px;
  }
  .inner-banner .inner-title ul li:first-child {
    margin-left: 0;
  }
  .inner-banner .inner-img {
    text-align: center;
  }
  .faq-accordion .panel-group .panel .panel {
    padding: 10px;
  }
  .faq-accordion .panel-group .panel .panel .panel-heading .btn-link {
    padding: 8px 0 8px 58px;
    font-size: 15px;
  }
  .faq-accordion .panel-group .panel .panel .card-body {
    padding-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
  }
  .pagination-area {
    margin-top: 10px;
  }
  .pagination-area .page-numbers {
    width: 42px;
    height: 42px;
    font-size: 16px;
    line-height: 41px;
  }
  .product-quickview-modal {
    max-width: 100%;
  }
  .product-quickview-modal .product-details-image img {
    padding: 40px;
  }
  .product-quickview-modal .product-details-desc {
    padding-left: 0;
  }
  .product-quickview-modal .product-details-desc h3 {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .product-quickview-modal .product-details-desc .rating i {
    font-size: 15px;
  }
  .product-quickview-modal .product-details-desc .price {
    font-size: 15px;
  }
  .product-quickview-modal .product-details-desc .product-category-list {
    margin-bottom: 15px;
  }
  .product-quickview-modal .product-details-desc .product-category-list li {
    max-width: 100%;
  }
  .product-quickview-modal .product-details-desc .product-add-btn {
    margin-bottom: 15px;
  }
  .product-quickview-modal .product-details-desc .product-add-btn .products-action {
    top: 5px;
  }
  .product-quickview-modal .product-details-desc .product-add-btn .products-action li a {
    width: 45px;
    height: 45px;
    font-size: 18px;
    line-height: 45px;
  }
  .product-quickview-modal .product-details-desc .product-share ul li span {
    font-size: 14px;
  }
  .product-quickview-modal .product-details-desc .product-share ul li a {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
  }
  .product-details-tabs .nav-tabs {
    margin-bottom: 10px;
  }
  .product-details-tabs .nav-tabs .nav-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .product-details-tabs .nav-tabs .nav-item .nav-link {
    font-size: 16px;
  }
  .product-details-tabs .tab-content {
    padding: 20px;
  }
  .product-details-tabs .tab-content .additional-information li {
    padding: 10px;
  }
  .product-details-tabs .tab-content .product-details-comments h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-title {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-title .rating i {
    font-size: 15px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-title .rating p {
    top: 1px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-title .default-btn {
    margin-top: 12px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-comments .review-item {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content img {
    max-width: 50px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content .content-dtls h4 {
    font-size: 15px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content .content-dtls span {
    font-size: 13px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-comments .review-item h3 {
    font-size: 17px;
    margin-top: 18px;
    margin-bottom: 8px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-form h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-form form .form-group {
    margin-bottom: 15px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-form form .form-group .form-control {
    height: 50px;
    font-size: 14px;
    padding: 10px 15px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-form form .form-group textarea.form-control {
    padding-top: 15px;
  }
}
/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .inner-banner .container-fluid {
    max-width: 540px;
  }
  .product-quickview-modal {
    max-width: 540px;
  }
}
/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-100 {
    padding-top: 80px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .ptb-75 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .pt-75 {
    padding-top: 55px;
  }
  .pb-75 {
    padding-bottom: 55px;
  }
  .section-title {
    margin-bottom: 35px;
  }
  .section-title h2 {
    font-size: 33px;
  }
  .section-title p {
    max-width: 600px;
  }
  .default-btn {
    padding: 11px 25px;
    font-size: 15px;
  }
  .default-btn.with-icon {
    padding: 11px 47px 11px 25px;
  }
  .default-btn.with-icon i {
    right: 25px;
  }
  .services-slider .owl-theme .owl-nav {
    margin-bottom: 25px;
  }
  .services-slider .owl-theme .owl-nav [class*=owl-] {
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    position: relative;
    transform: translateY(0);
    margin-left: 4px;
    margin-right: 4px;
  }
  .services-slider .owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }
  .banner-slider .owl-theme .owl-nav [class*=owl-] {
    width: 40px;
  }
  .inner-banner .container-fluid {
    max-width: 720px;
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
  }
  .inner-banner .inner-title h2 {
    font-size: 33px;
  }
  .faq-accordion .panel-group .panel .panel {
    padding: 15px;
  }
  .faq-accordion .panel-group .panel .panel .panel-heading .btn-link {
    padding: 8px 0 8px 60px;
    font-size: 16px;
  }
  .faq-accordion .panel-group .panel .panel .card-body {
    padding-top: 15px;
  }
  .pagination-area .page-numbers {
    width: 45px;
    height: 45px;
    font-size: 18px;
    line-height: 44px;
  }
  .product-quickview-modal {
    max-width: 720px;
  }
  .product-quickview-modal .product-details-image img {
    padding: 50px;
  }
  .product-quickview-modal .product-details-desc {
    padding-left: 0;
  }
  .product-quickview-modal .product-details-desc h3 {
    margin-bottom: 15px;
    font-size: 20px;
  }
  .product-quickview-modal .product-details-desc .rating i {
    font-size: 17px;
  }
  .product-quickview-modal .product-details-desc .price {
    font-size: 16px;
  }
  .product-quickview-modal .product-details-desc .product-category-list {
    margin-bottom: 15px;
  }
  .product-quickview-modal .product-details-desc .product-add-btn {
    margin-bottom: 20px;
  }
  .product-quickview-modal .product-details-desc .product-add-btn .default-btn {
    margin-right: 15px;
  }
  .product-quickview-modal .product-details-desc .product-add-btn .products-action li a {
    width: 46px;
    height: 46px;
    font-size: 20px;
    line-height: 46px;
  }
  .product-details-tabs .nav-tabs .nav-item .nav-link {
    font-size: 17px;
  }
  .product-details-tabs .tab-content {
    padding: 30px;
  }
  .product-details-tabs .tab-content .additional-information li {
    padding: 13px 15px;
  }
  .product-details-tabs .tab-content .product-details-comments h3 {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-title .rating i {
    font-size: 17px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content .content-dtls h4 {
    font-size: 16px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-comments .review-item .content .content-dtls span {
    font-size: 14px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-comments .review-item h3 {
    font-size: 18px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-form h3 {
    font-size: 20px;
  }
  .product-details-tabs .tab-content .product-details-comments .review-form form .form-group .form-control {
    font-size: 15px;
  }
}
/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2 {
    font-size: 38px;
  }
  .services-slider .owl-theme .owl-nav {
    margin-bottom: 25px;
  }
  .services-slider .owl-theme .owl-nav [class*=owl-] {
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    position: relative;
    transform: translateY(0);
    margin-left: 4px;
    margin-right: 4px;
  }
  .services-slider .owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }
  .inner-banner .container-fluid {
    max-width: 960px;
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
  }
  .inner-banner .inner-title h2 {
    font-size: 38px;
  }
  .faq-accordion .panel-group .panel .panel .panel-heading .btn-link {
    font-size: 17px;
  }
  .product-quickview-modal {
    max-width: 960px;
  }
  .product-quickview-modal .product-details-desc {
    padding-left: 0;
  }
  .product-quickview-modal .product-details-desc h3 {
    font-size: 22px;
  }
  .product-quickview-modal .product-details-desc .price {
    font-size: 17px;
  }
}
/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .services-slider .owl-theme .owl-nav [class*=owl-] {
    left: -35px;
    width: 35px;
    height: 35px;
  }
  .services-slider .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -35px;
  }
  .inner-banner .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  .product-quickview-modal {
    max-width: 1140px;
  }
}
/* Min width 1600px */
@media only screen and (min-width: 1600px) {
  .banner-slider .owl-theme .owl-nav [class*=owl-] {
    width: 50px;
    font-size: 20px;
  }
  .inner-banner .container-fluid {
    max-width: 1640px;
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
  }
}